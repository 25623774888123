import React, { memo } from "react";
import Modal from "react-bootstrap/Modal";
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import RenderMap from "./RenderMap";

const render = (status) => {
  if (status === Status.FAILURE) return "Error";
  return "Loading";
};

const LocationModal = ({ handleClose, ...rest }) => {
  return (
    <Modal show onHide={handleClose} animation={false} size="xl">
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <Wrapper apiKey={process.env.REACT_APP_MAP_KEY} render={render}>
          <RenderMap {...rest} />
        </Wrapper>
      </Modal.Body>
      <Modal.Footer>
        <button className="btnclass" onClick={handleClose}>close</button>
      </Modal.Footer>
    </Modal>
  );
};

export default memo(LocationModal);
